<template> 
    <v-hover #default="{ hover }">
        <v-card
            v-resize="onResize"
            :height="isDashboard ? 450 : 600" 
            :loading="isLoading"
            :elevation="(!isDashboard || !isEditMode) ? 2 : ( hover ? 20 : 5 )"
        >
            <v-card-title>
                {{ name }}の時系列推移
                <v-spacer></v-spacer>
                <v-fade-transition>
                    <v-btn
                        v-if="!isDashboard || isEditMode"
                        icon
                        @click="onClickClose"
                    ><v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-fade-transition>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4" class="pt-1 pb-0">
                        <v-select
                            outlined
                            v-model="selectedDataType"
                            dense
                            offset-y
                            label="描画するデータ"
                            :items="dataTypes"
                            item-text="text"
                            itme-value="value"
                            prepend-inner-icon="mdi-chart-bar"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="pt-1 pb-0">
                        <v-select
                            v-model="dataUnit"
                            dense
                            offset-y
                            outlined
                            item-text="text"
                            itme-value="value"
                            label="表示単位"
                            prepend-inner-icon="mdi-chart-bar"
                            :items="dataUnits"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="pt-1 pb-0">
                        <v-slide-x-transition>
                            <v-combobox
                                v-if="selectedDataType === 'shipments'"
                                v-model="combobox"
                                deletable-chips
                                dense
                                multiple
                                outlined
                                small-chips
                                label="比較する予測値を選ぶ"
                                :items="comboboxItems"
                                item-text="text"
                                item-value="value"
                            >
                                <!--<template #selection="{ attrs, item, parent, selected }">
                                    <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        color="grey darken-1"
                                        :input-value="selected"
                                        label
                                        small
                                        dark
                                    >          
                                        <span class="pr-2 chip-label">{{ item.text }}</span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >mdi-close
                                        </v-icon>
                                    </v-chip>
                                </template>-->
                            </v-combobox>
                        </v-slide-x-transition>
                    </v-col>
                </v-row>
                <yw-range-menu
                    row-class="mt-0"
                    :acceptable-year="ywRangeAcceptableYear"
                    :initial-yw-range="[startYw, endYw]"
                    :chip-props="{
                        small: isDashboard ? true : false,
                        outlined: true,
                        label: true
                    }"
                    @click="getYwRange"
                ></yw-range-menu>
                <!--<v-row class="mt-0">
                    <v-col class="pt-0">
                        <v-checkbox
                            v-model="isChecked"
                            hide-details
                            label="欠品率を表示"
                            :ripple="false"
                        ></v-checkbox>
                    </v-col>
                </v-row>-->
                <v-row v-if="isNoData">
                    <v-col cols="12" class="pl-4 py-0">
                        <v-alert
                            dense
                            type="warning"
                        >どの週にもデータがありません。
                        </v-alert>
                    </v-col>
                </v-row>
                <div ref="chart"></div>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script>
import Plotly from 'plotly.js-dist-min'
import YwRangeMenu from './ui/YwRangeMenu'
export default {
    components: { YwRangeMenu },
    data() {
        return {
            cardWidth: 0,
            isChecked:  false,
            isEditMode: true,
            isLoading:  false,
            isNoData:   false,
            dataUnit:  'total_price',
            selectedDataType: '',
            startYw: { year: null, week: null },
            endYw:   { year: null, week: null },
            ywRangeAcceptableYear: [],
            combobox: [],
            comboboxItems: [
                { text: '商品マスタに基づく予測値', value: 'master' },
                { text: '事後確率最大となる予測値', value: 'posterior' },
            ],
            dataTypes: [
                { text: '在庫',   value: 'stocks' },
                { text: '出荷',   value: 'shipments' },
                { text: '発注残', value: 'remains' },
                { text: '欠品率', value: 'stockout' }
            ],
        }
    },
    computed: {
        inputs() {
            return this.initialAbsTimeRange.length === 0 ? [ 
                this.initialRelTimeRange.yw.year, 
                this.initialRelTimeRange.yw.week, 
                this.initialDataType,
                this.name,
                this.code
            ] : [ 
                this.initialAbsTimeRange, 
                this.initialDataType,
                this.name,
                this.code
            ] 
        },
        watchersForSettingChart() {
            return [ 
                ...this.combobox, 
                ...Object.values(this.startYw), 
                ...Object.values(this.endYw),
                this.dataUnit, 
                this.selectedDataType, 
            ]
        },
        dataUnits() {
            return this.selectedDataType === 'stockout' ? [
                { text: '割合', value: 'stockout' },
            ] : [
                { text: '合計金額', value: 'total_price' },
                { text: 'バラ数', value: 'amount' }
            ]
        }
    },
    props: {
        duct: Object,
        name: String,
        code: String,
        isDashboard: {
            type: Boolean,
            default: false
        },
        currentYw: {
            type: Object,
            default: () => ({})
        },
        initialDataType: {
            type: String,
            default: 'not_selected'
        },
        initialAbsTimeRange: {
            type: Array,
            default: () => ([])
        },
        initialRelTimeRange: {
            type: Object,
            default: () => ({
                yw: {},
                range: 0
            })
        },
        initialForecastType: {
            type: Array,
            default: () => ([])
        }
    },
    watch: {
        inputs() { this.updateIpChartData(); },
        watchersForSettingChart() { this.setChart(); },
        selectedDataType(dataType) {
            this.dataUnit = 'total_price';
            if(dataType === 'shipments') {
                this.combobox = this.initialForecastType; 
            }else {
                this.combobox = [];
                if(dataType === 'stockout') { this.dataUnit = 'stockout'; }
            }
        },
        isChecked() { this.setChart(); }
    },
    methods: {
        async setChart() {
            this.switchisLoadingState();
            await this.renderPlotly();
            this.switchisLoadingState();
        },
        updateIpChartData() {
            this.ywRangeAcceptableYear = [ ...Array(5) ].map((_, i) => this.currentYw.year - i).sort();
            this.selectedDataType = this.initialDataType;
            this.dataUnit = this.selectedDataType === 'stockout' ? 'stockout' : 'total_price';
            this.duct.invokeOnOpen(async () => {
                if(this.initialAbsTimeRange.length != 0) {
                    [this.startYw, this.endYw] = this.initialAbsTimeRange;
                    if(this.initialDataType != 'not_selected') this.setChart();
                }
            });
            if(Object.keys(this.initialRelTimeRange.yw).length != 0) {
                this.endYw = this.initialRelTimeRange.yw;
                this.startYw = this.endYw.week > this.initialRelTimeRange.range ? {
                    year: this.endYw.year,
                    week: this.endYw.week - this.initialRelTimeRange.range
                } : { 
                    year: this.endYw.year - 1, 
                    week: 52 - (this.initialRelTimeRange.range - this.endYw.week) 
                };
            } 
        },
        async renderPlotly() {
            let tickSuffix = '';
            let titleText  = '';
            if(this.dataUnit === 'total_price') {
                tickSuffix = '円';
                titleText  = '合計金額';
            }else if(this.dataUnit === 'amount'){
                tickSuffix = '個';
                titleText  = '個数';
            }else {
                tickSuffix = '';
                titleText  = '欠品率';
            }
            const layout = { 
                barmode: 'stack',
                height: this.isDashboard ? 276 : 426, 
                legend: { 'orientation': 'h' }, 
                margin: { r: 0, t: 0, b: this.isDashboard ? 40 : 20 },
                xaxis: this.isDashboard ? { 
                    type: 'category',
                    tickfont: { size: 10 }
                } : { type: 'category' },
                yaxis: { 
                    automargin: 'left',
                    exponentformat: 'none',
                    tickfont: this.isDashboard ? { size: 10 } : {},
                    ticksuffix: tickSuffix,
                    tickformat: this.selectedDataType === 'stockout' ? ',.1%': '',
                    title: {
                        font: this.isDashboard ? { size: 12 } : {},
                        text: titleText,
                        standoff: 20
                    },
                }
            };
            const config = {
                modeBarButtonsToRemove: ['resetScale2d', 'zoom', 'zoomIn2d', 'zoomOut2d', 'select2d', 'pan2d', 'lasso2d']
            };
            let lineChartData = [];

            const canClientCallDuct = Object.values(this.startYw).every(el => el !== null) && Object.values(this.endYw).every(el => el !== null) && this.selectedDataType !== ''
            if(canClientCallDuct){
                const _ductRetArray = await this.callDuct('CHART_IND_PROD_GET', {
                    prod_code: this.code,
                    data_type: this.selectedDataType, 
                });
                const barChartData = this.composePlotlyData(_ductRetArray);

                if(this.combobox.length != 0){
                    for(let [idx, orderPointType] of this.combobox.map(el => el.value).entries()){
                        const _ductRetArray = await this.callDuct('CHART_ML_RESULT_GET', {
                            prod_code: this.code,
                            demand_type: orderPointType
                        });
                        lineChartData = [ ...lineChartData , ...this.composePlotlyData(_ductRetArray, false, orderPointType, idx)];
                    }
                }

                const data = [ ...barChartData, ...lineChartData ];
                console.log(data);
                this.isNoData = data.length == 0 || data.every(chartData => chartData.y.every(el => el === null));
                if(!this.isNoData) Plotly.newPlot(this.$refs.chart, data, layout, config);
            }
        },
        async callDuct(_evt, _arg) {
            let _ductRetArray = [];
            const _deviation = (this.endYw.year - this.startYw.year) * 52 + this.endYw.week - this.startYw.week;
            for(let idx = 0; idx < _deviation + 1; idx++) {
                let _week = this.startYw.week + idx;
                let _year = this.startYw.year;
                while(_week > 52) {
                    _week = _week - 52;
                    _year++;
                }
                let _ductRet = await this.duct.call(this.duct.EVENT[_evt], {
                    ..._arg,
                    year: Number(_year), 
                    week: Number(_week)
                });
                console.log(_ductRet)
                _ductRetArray.push({ 
                    week: `${_year}-${_week}`,
                    content: (_ductRet.content === 'no_data' || !_ductRet.success) ? [] : _ductRet.content
                });
                console.log(_ductRetArray)
            }
            return _ductRetArray
        },
        composePlotlyData(ductRetArray, isBar=true, orderPointType='', idx) {
            let data = [{
                x: ductRetArray.map(el => el.week),
                y: ductRetArray.map(el => el.content.length != 0 ? Number(el.content[0][this.dataUnit]) : null),
                type:   isBar ? 'bar' : 'line',
                name:   isBar ? '実績値' : (orderPointType == 'master' ? '商品マスタに基づく予測値' : '事後確率最大となる予測値'),
                line:   isBar ? {} : { 
                    dash: idx === 0 ? 'solid' : 'dot',
                    width: 3 
                },
                connectgaps: true,
                marker: isBar ? {
                    color: ductRetArray.map(el => el.content.length != 0 ? '#1F77B4' : '#FF7043')
                } : { 
                    size: 10 
                }
            }];
            if(isBar && data[0].y.some(el => el !== null)) {
                for(const idx in data[0].y) {
                    if(idx !== 0) {
                        data[0].y[idx] = data[0].y[idx] === null ? data[0].y[idx-1] : data[0].y[idx]
                    }
                }
            }
            return data
        },
        onResize() {
            const chartRef = this.$refs.chart;
            const hasChartRendered = chartRef.classList.contains('js-plotly-plot');
            if(![this.cardWidth, 0].includes(chartRef.clientWidth) && hasChartRendered) {
                this.cardWidth = chartRef.clientWidth;
                Plotly.relayout(this.$refs.chart, { width: this.cardWidth });
            }
        },
        getYwRange(arg) {
            this.startYw = this.startYw !== arg[0] ? arg[0] : this.startYw;
            this.endYw   = this.endYw   !== arg[1] ? arg[1] : this.endYw;
        },
        onClickClose() { this.$emit('click:close'); },
        switchisLoadingState() { this.isLoading = !this.isLoading; },
        changeEditModeState(bool) { this.isEditMode = bool; },
        purgeChart() { Plotly.purge(this.$refs.chart); }
    },
    created() { 
        this.updateIpChartData(); 
    },
}
</script>
<style scoped>
.chip-label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
</style>