<template>
    <v-menu offset-y>
        <template #activator="{ on,attrs }">
            <v-chip
                v-on="on"
                v-bind="{ ...attrs, ...chipProps }"
                :disabled="disabled"
                :class="chipClass"
            >{{ chipText }}<v-icon x-small right>mdi-chevron-down</v-icon>
            </v-chip>
        </template>
        <v-card max-height="200">
            <v-list class="py-0">
                <v-list-item
                    v-for="(item, idx) in listItems"
                    :key="`item_${idx}`"
                    dense
                    @click="emitListItem(item)"
                ><v-list-item-title>{{ typeof item !== 'object' ? item : item.text }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
export default{
    props: {
        listItems: {
            type: Array,
            default: () => ([])
        },
        chipText: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        chipProps: {
            type: Object,
            default: () => ({})
        },
        chipClass: {
            type: [String, Array],
            default: ''
        }
    },
    methods: {
        emitListItem(item) {
            this.$emit('click:list', item);
        }
    }
}
</script>