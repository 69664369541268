<template>
    <v-hover #default="{ hover }">
        <v-card
            v-resize="onResize"
            :height="isDashboard ? 450 : 600" 
            :loading="isLoading"
            :elevation="(!isDashboard || !isEditMode) ? 2 : (hover ? 20 : 5)"
        >
            <v-card-title class="pb-0">
                {{ initialYw.year }}年第{{ initialYw.week }}週の{{ dataTypeText }}内訳
                <v-spacer></v-spacer>
                <v-fade-transition>
                    <v-btn
                        v-if="!isDashboard || isEditMode"
                        icon
                        @click="onClickClose"
                    ><v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-fade-transition>
            </v-card-title>
            <v-card-text>
                <v-row class="ml-0 mt-0">
                        <v-checkbox
                            v-model="isDeviation"
                            :disabled="initialDataType === 'stockout'"
                            label="差分を見る:"
                            hide-details
                        ></v-checkbox>
                        <v-col cols="8" class="pt-4">
                            <chip-menu
                                :disabled="!isDeviation"
                                :list-items="years"
                                :chip-text="`${String(compYw.year)}年`"
                                :chip-props="{
                                    outlined: true,
                                    label: true,
                                }"
                                chip-class="mr-1" 
                                @click:list="compYw.year=$event"
                            ></chip-menu>
                            <chip-menu
                                :disabled="!isDeviation"
                                :list-items="weeks"
                                :chip-text="`第${String(compYw.week)}週`"
                                :chip-props="{
                                    outlined: true,
                                    label: true,
                                }"
                                chip-class="mr-1" 
                                @click:list="compYw.week=$event"
                            ></chip-menu>
                            との比較
                        </v-col>
                </v-row>
                <v-row class="mt-5 d-flex flex-row align-baseline">
                    <chip-menu
                        :list-items="dataUnits"
                        :chip-text="dataUnits.find(el => el.value == dataUnit).text"
                        :chip-props="{
                            outlined: true,
                            label: true,
                            small: true
                        }"
                        chip-class="ml-4" 
                        @click:list="dataUnit=$event.value"
                    ></chip-menu>
                    <chip-menu
                        :list-items="rankingOrders"
                        :chip-text="rankingOrders.find(el => el.value == rankingOrder).text"
                        :chip-props="{
                            outlined: true,
                            label: true,
                            small: true
                        }"
                        chip-class="mr-1 ml-1" 
                        @click:list="rankingOrder=$event.value"
                    ></chip-menu>
                    <v-col class="py-0" cols="2">
                        <v-text-field
                            v-model="ranking[0]"
                            dense
                            label="最高順位"
                            suffix="位"
                            @blur="setChart()"
                        ></v-text-field>
                    </v-col>
                    から
                    <v-col class="py-0" cols="2">
                        <v-text-field
                            v-model="ranking[1]"
                            dense
                            label="最低順位"
                            suffix="位"
                            @blur="setChart()"
                        ></v-text-field>
                    </v-col>
                    までを表示
                    <v-btn
                        icon
                        @click="setChart"
                    ><v-icon>mdi-reload</v-icon>
                    </v-btn>
                </v-row>
                <v-row v-if="!isRankingValid">
                    <v-col cols="12" class="pl-4 py-0">
                        <v-alert
                            dense
                            type="warning"
                        >無効な順位です。
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="isDataUnavailable">
                    <v-col cols="12" class="pl-4 py-0">
                        <v-alert
                            dense
                            type="warning"
                        >該当週または比較週のデータが存在しません。
                        </v-alert>
                    </v-col>
                </v-row>
                <v-menu
                    absolute
                    open-on-hover
                    transition="fade-transition"
                    :open-on-click="false"
                    :close-on-click="false"
                    :close-on-content-click="false"
                    :position-x="tooltipInfo.positionX ? tooltipInfo.positionX : 0"
                    :position-y="tooltipInfo.positionY ? tooltipInfo.positionY : 0"
                >
                    <template #activator="{ on, attrs }">
                        <div 
                            ref="chart"
                            v-on="on"
                            v-bind="attrs"
                        ></div>
                    </template>
                    <v-card v-show="isTooltipShown">
                        <v-card-text>
                            <ul v-if="['stocks', 'shipments', 'remains'].includes(initialDataType)">
                                <li>商品名：{{ tooltipInfo.productName }}</li>
                                <li>商品コード：{{ tooltipInfo.productCode }}</li>
                                <li>仕入先：{{ tooltipInfo.supplier }}</li>
                                <li>ランク：{{ tooltipInfo.rank }}</li>
                                <li>単価：{{ tooltipInfo.price }}円</li>
                                <li>バラ数：{{ tooltipInfo.amount }}</li>
                                <li>合計金額：{{ tooltipInfo.totalPrice }}円</li>
                            </ul>
                            <ul v-if="initialDataType === 'products'">
                                <li>仕入先名：{{ tooltipInfo.supplierName }}</li>
                                <li>仕入先コード：{{ tooltipInfo.supplierCode }}</li>
                                <li>商品数：{{ tooltipInfo.amount }}</li>
                            </ul>
                            <ul v-if="initialDataType === 'stockout'">
                                <li>商品名：{{ tooltipInfo.productName }}</li>
                                <li>商品コード：{{ tooltipInfo.productCode }}</li>
                                <li>仕入先：{{ tooltipInfo.supplier }}</li>
                                <li>ランク：{{ tooltipInfo.rank }}</li>
                                <li>単価：{{ tooltipInfo.price }}円</li>
                                <li>欠品率：{{ tooltipInfo.stockout }}%</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script>
import Plotly from 'plotly.js-dist-min'
import ChipMenu from './ui/ChipMenu'
export default {
    components: { ChipMenu },
    data:() => ({
        cardWidth: 0,
        isDataUnavailable: false, 
        isDeviation: false,
        isEditMode: true,
        isLoading: false,
        isRankingValid: true,
        isTooltipShown: false,
        compYw: { year: 0, week: 0 },
        tooltipInfo: {},
        pointerPosition: [0, 0],
        ranking: [1, 10],
        years: [],
        weeks: [ ...Array(52) ].map((_, idx) => Number(idx) + 1),
        dataUnit: 'total_price',
        rankingOrder: 'descend',
        rankingOrders: [
            { text: '多い順に', value: 'descend' },
            { text: '少ない順に', value: 'ascend' },
        ],
        dataTypes: [
            { text: '在庫', value: 'stocks' },
            { text: '出荷', value: 'shipments' },
            { text: '発注残', value: 'remains' },
            //{ text: '欠品率', value: 'stockout' },
            //{ text: '発注有無', value: 'is_ordered' },
            { text: '商品数', value: 'products' },
            { text: '顧客数', value: 'customers' }
        ],
    }),
    computed: {
        dataTypeText() {
            return this.dataTypes.find(el => el.value == this.initialDataType) ? this.dataTypes.find(el => el.value == this.initialDataType).text : ''
        },
        dataUnits() {
            let _options = [];
            if(['stocks', 'shipments', 'remains'].includes(this.initialDataType)) {
                _options = [
                    { text: '合計金額', value: 'total_price' },
                    { text: 'バラ数', value: 'amount' },
                ];
            }else if(this.initialDataType == 'products') {
                _options = [ { text: '仕入先別商品数', value: 'supplier_amount' } ];
            }else if(this.initialDataType == 'stockout') {
                _options = [ { text: '欠品率', value: 'stockout' } ];
            }
            return _options
        },
        inputs() {
            return [ this.initialYw.year, this.initialYw.week, this.initialDataType, this.initialIsDeviation ]
        },
        watchersForSettingChart() {
            return [ this.dataUnit, this.rankingOrder, this.isDeviation, this.compYw.year, this.compYw.week ]
        }
    },
    props: {
        duct: Object,
        currentYw: {
            type: Object,
            default: () => ({})
        },
        initialYw: {
            type: Object,
            default: () => ({ year: 0, week: 0 })
        },
        initialDataType: {
            type: String,
            default: ''
        },
        initialIsDeviation: {
            type: Boolean,
            default: false
        },
        isDashboard: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        inputs() { this.updateInputs(); },
        watchersForSettingChart() { this.setChart(); },
        dataUnits() {
            if(!this.dataUnits.map(el => el.value).includes(this.dataUnit) && this.dataUnits.length != 0) this.dataUnit = this.dataUnits[0].value;
        },
    },
    methods: {
        updateInputs() {
            this.isDeviation = this.initialIsDeviation
            this.compYw = {
                year: this.initialYw.week != 1 ? this.initialYw.year : this.initialYw.year - 1,
                week: this.initialYw.week != 1 ? this.initialYw.week - 1 : 52
            };
            this.years = [ ...Array(5) ].map((_, i) => this.currentYw.year - i).sort();
        },
        async setChart() {
            this.isRankingValid = this.validateRanking();
            if(this.isRankingValid) {
                this.switchisLoadingState();
                await this.renderPlotly();
                this.switchisLoadingState();
            }
        },
        async renderPlotly() {
           const _ductRetArray = await this.callDuct();
           const data = this.composePlotlyData(_ductRetArray);
           const layout = {
                height: this.isDashboard ? 276 : 426,
                margin: { l: 10, r: 0, t:20, b: 40 },
                xaxis: {
                    exponentformat: 'none',
                    title: {
                        font: this.isDashboard ? { size: 12 } : {}, 
                        text: `${ this.dataUnits.find(el => el.value == this.dataUnit).text }`
                    },
                    tickfont: this.isDashboard ? { size: 10 } : {},
                    tickformat: this.initialDataType === 'stockout' ? ',.1%': '',
                },
                yaxis: { visible: false },
            }
            const config = {
                modeBarButtonsToRemove: ['resetScale2d', 'zoom', 'zoomIn2d', 'zoomOut2d', 'select2d', 'pan2d', 'lasso2d']
            };
            if(!this.isDataUnavailable) {
                Plotly.newPlot(this.$refs.chart, data, layout, config);
                this.definePlotlyEvents();
            }
        },
        async callDuct() {
            let _ductRet = await this.duct.call(this.duct.EVENT.CHART_RANKING_GET, {
                is_deviation: this.isDeviation,
                data_type: this.initialDataType,
                data_unit: this.dataUnit,
                ranking_order: this.rankingOrder,
                ranking: this.ranking,
                yw: [ this.initialYw.year, this.initialYw.week ],
                comp_yw: [ this.compYw.year, this.compYw.week ]
            });
            console.log(_ductRet)
            const _ductRetArray = _ductRet.content === 'no_data' ? [] : _ductRet.content;
            this.isDataUnavailable = _ductRet.success ? false : true;
            return _ductRetArray
        },
        composePlotlyData(ductRetArray) {
            const revDuctRetArray = ductRetArray.reverse();
            console.log(revDuctRetArray)
            const defaultDataProps = {
                type: 'bar',
                orientation: 'h',
                showlegend: false,
                hoverinfo: 'none',
                textposition: 'inside'
            };
            let data = [];
            if(['stocks', 'shipments', 'remains'].includes(this.initialDataType)) {
                data = [{
                    ...defaultDataProps, 
                    x: revDuctRetArray.map(el => el[this.dataUnit]),
                    y: revDuctRetArray.map(el => 'prd_' + String(el.product_code)),
                    text: revDuctRetArray.map(el => el.product_name),
                    totalPrice: revDuctRetArray.map(el => el.total_price),
                    productCode: revDuctRetArray.map(el => el.product_code),
                    supplier: revDuctRetArray.map(el => el.supplier),
                    price: revDuctRetArray.map(el => el.price),
                    amount: revDuctRetArray.map(el => el.amount),
                    rank: revDuctRetArray.map(el => el.rank),
                }];
            }else if(this.initialDataType == 'products') {
                data = [{
                    ...defaultDataProps,
                    x: revDuctRetArray.map(el => el[this.dataUnit]),
                    y: revDuctRetArray.map(el => el.attr_supplier_name),
                    supplierCode: revDuctRetArray.map(el => el.attr_supplier_code),
                }];
            }else if (this.initialDataType == 'stockout') {
                data = [{
                    ...defaultDataProps,
                    x: revDuctRetArray.map(el => el[this.dataUnit]),
                    y: revDuctRetArray.map(el => 'prd_' + String(el.product_code)),
                    text: revDuctRetArray.map(el => el.product_name),
                    productCode: revDuctRetArray.map(el => el.product_code),
                    supplier: revDuctRetArray.map(el => el.supplier),
                    price: revDuctRetArray.map(el => el.price),
                    rank: revDuctRetArray.map(el => el.rank)             
                }]
            }
            return data
        },
        definePlotlyEvents() {
            this.$refs.chart.on('plotly_hover', (data) => {
                const _idx = data.points[0].pointIndex;
                if(['stocks', 'shipments', 'remains'].includes(this.initialDataType)) {
                    this.tooltipInfo = {
                        productName: data.points[0].text,
                        totalPrice: data.points[0].data.totalPrice[_idx],
                        productCode: data.points[0].data.productCode[_idx],
                        supplier: data.points[0].data.supplier[_idx],
                        price: Math.round(data.points[0].data.price[_idx] * 10000) / 10000,
                        amount: data.points[0].data.amount[_idx],
                        rank: data.points[0].data.rank[_idx],
                        positionX: data.event.clientX + 10, 
                        positionY: data.event.clientY
                    };
                }else if(this.initialDataType === 'products') {
                    this.tooltipInfo = {
                        supplierName: data.points[0].y,
                        supplierCode: data.points[0].data.supplierCode[_idx],
                        amount: data.points[0].x,
                        positionX: data.event.clientX + 10, 
                        positionY: data.event.clientY
                    };
                }else if(this.initialDataType === 'stockout') {
                    this.tooltipInfo = {
                        productName: data.points[0].text,
                        productCode: data.points[0].data.productCode[_idx],
                        supplier: data.points[0].data.supplier[_idx],
                        price: Math.round(data.points[0].data.price[_idx] * 10000) / 10000,
                        rank: data.points[0].data.rank[_idx],
                        stockout: Math.round(data.points[0].x * 100 * 10000) / 10000,
                        positionX: data.event.clientX + 10, 
                        positionY: data.event.clientY
                    };
                }
                this.isTooltipShown = true;
            });
            this.$refs.chart.on('plotly_unhover', () => { this.isTooltipShown = false; });

            this.$refs.chart.on('plotly_click', (data) => {
                this.tooltipInfo.positionX = data.event.pointerX;
                this.tooltipInfo.positionY = data.event.pointerY;
                this.$emit('click:bar', 
                    ['stocks', 'shipments', 'remains', 'stockout'].includes(this.initialDataType) ? { 
                        code: this.tooltipInfo.productCode,
                        name: this.tooltipInfo.productName
                    } : {
                        code: this.tooltipInfo.supplierCode,
                        name: this.tooltipInfo.supplierName
                    }
                );
            });
        },
        onResize() {
            const chartRef = this.$refs.chart;
            if(![this.cardWidth, 0].includes(chartRef.clientWidth)) {
                this.cardWidth = chartRef.clientWidth;
                if(chartRef.classList.contains('js-plotly-plot')) {
                    Plotly.relayout(this.$refs.chart, { width: this.cardWidth });
                }
            }
        },
        onClickClose() { this.$emit('click:close') },
        validateRanking() { return this.ranking[0] < this.ranking[1] ? true : false },
        switchisLoadingState(){ this.isLoading = !this.isLoading; },
        changeEditModeState(bool) { this.isEditMode = bool; },
        purgeChart() { Plotly.purge(this.$refs.chart); }
    },
    created() {
        this.updateInputs();
        if(!this.dataUnits.map(el => el.value).includes(this.dataUnit) && this.dataUnits.length != 0) this.dataUnit = this.dataUnits[0].value;
        window.onmousemove = getPointerPosition;
        function getPointerPosition(event) {
            event = event || window.event;
            this.pointerPosition = [event.clientX, event.clientY];
        }
    },
}
</script>
