<template> 
    <v-hover #default="{ hover }">
        <v-card
            v-resize="onResize"
            :height="isDashboard ? 450 : 600"
            :loading="isLoading"
            :elevation="(!isDashboard || !isEditMode) ? 2 : (hover ? 20 : 5)"
        >
            <v-card-title>
                全商品対象の時系列推移
                <v-spacer></v-spacer>
                <v-fade-transition>
                    <v-btn
                        v-if="isDashboard && isEditMode"
                        icon
                        @click="onClickClose"
                    ><v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-fade-transition>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4" class="pt-1 pr-1 pb-0">
                        <v-select
                            v-model="selectedDataTypes[0]"
                            outlined
                            dense
                            offset-y
                            label="棒グラフに描画するデータ"
                            :items="dataTypes"
                            item-text="text"
                            itme-value="value"
                            prepend-inner-icon="mdi-chart-bar"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="pt-1 pr-1 pb-0">
                        <v-select
                            v-model="selectedDataTypes[1]"
                            outlined
                            dense
                            offset-y
                            label="折線グラフに描画するデータ"
                            :items="dataTypes"
                            item-text="text"
                            itme-value="value"
                            prepend-inner-icon="mdi-chart-line"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="pt-1 pb-0">
                        <v-select
                            v-model="selectedClassif"
                            outlined
                            dense
                            offset-y
                            label="棒グラフの分類方法"
                            :items="classifTypes"
                            item-text="text"
                            itme-value="value"
                            prepend-inner-icon="mdi-chart-bar-stacked"
                        ></v-select>
                    </v-col>
                </v-row>
                <yw-range-menu
                    row-class="mt-0"
                    :acceptable-year="ywRangeAcceptableYear"
                    :initial-yw-range="[startYw, endYw]"
                    :chip-props="{
                        small: isDashboard ? true : false,
                        outlined: true,
                        label: true
                    }"
                    @click="getYwRange"
                ></yw-range-menu>
                <v-row class="mt-0">
                    <v-col class="pt-0">
                        <v-checkbox
                            v-model="isChecked"
                            hide-details
                            label="軸を揃える"
                            :ripple="false"
                            :disabled="!checkboxShown"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="isNoData">
                    <v-col cols="12" class="pl-4 py-0">
                        <v-alert
                            dense
                            type="warning"
                        >どの週にもデータがありません。
                        </v-alert>
                    </v-col>
                </v-row>
                <div ref="chart"></div>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script>
import Plotly from 'plotly.js-dist-min'
import YwRangeMenu from './ui/YwRangeMenu'
export default {
    components: { YwRangeMenu },
    data: function() {
        return {
            isChecked: false,
            isEditMode: true,
            isLoading: false,
            isNoData: false,
            cardWidth: 0,
            startYw: { year: null, week: null },
            endYw: { year: null, week: null },
            axesRange: [0, 0],
            ywRangeAcceptableYear: [],
            selectedDataTypes: [null, null],
            selectedClassif: 'no_classif',
            dataTypes: [
                { text: '在庫金額', value: 'stocks' },
                { text: '出荷金額', value: 'shipments' },
                { text: '発注残金額', value: 'remains' },
                { text: '欠品率', value: 'stockout' },
                //{ text: '発注有無', value: 'is_ordered' },
                { text: '商品数', value: 'products' },
                { text: '顧客数', value: 'customers' }
            ],
        }
    },
    props: { 
        duct: {
            type: Object,
            default: () => ({})
        },
        currentYw: {
            type: Object,
            default: () => ({})
        },
        initialDataType: {
            type: [String, Array],
            default: 'not_selected'
        },
        initialAbsTimeRange: {
            type: Array,
            default: () => ([])
        },
        initialRelTimeRange: {
            type: Object,
            default: () => ({ yw: {}, range: 10 })
        },
        initialClassif: {
            type: String,
            default: 'no_classif'
        },
        isDashboard: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classifTypes() {
            let arr = [];
            if(['stocks', 'shipments', 'remains', 'stockout'].includes(this.selectedDataTypes[0])) {
                arr = [
                    { text: '分類なし', value: 'no_classif' },
                    { text: 'ランク別', value: 'rank' },
                    { text: '安全率別', value: 'sr' },
                ];
            }else if(this.selectedDataTypes[0] == 'products') {
                arr = [
                    { text: '分類なし', value: 'no_classif' },
                    { text: 'ランク別', value: 'rank' },
                    { text: '安全率別', value: 'sr' },
                    { text: 'メーカー別', value: 'supplier' },
                ];
            }else if(this.selectedDataTypes[0] == 'customers') {
                arr = [ { text: '分類なし', value: 'no_classif' } ];
            }
            return arr
        },
        checkboxShown() {
            const isAllMoneyUnit = !['customers', 'products', 'stockout'].some(el => this.selectedDataTypes.includes(el)); 
            return (isAllMoneyUnit && !this.selectedDataTypes.includes(null)) ? true : false
        },
        watchersForSettingChart() {
            return [ 
                ...this.selectedDataTypes, 
                ...Object.values(this.startYw), 
                ...Object.values(this.endYw), 
                this.selectedClassif 
            ]
        }
    },
    watch: {
        initialRelTimeRange: {
            handler: function() {
                const currentYwObtained = Object.values(this.initialRelTimeRange.yw).every(el => typeof el === 'number');
                const startAndEndYwAreNumber = [this.startYw, this.endYw].every( yw => Object.values(yw).every(el => ![ NaN, undefined, null ].includes(el)))
                if(currentYwObtained && !startAndEndYwAreNumber) {
                    this.endYw = this.initialRelTimeRange.yw;
                    this.startYw = this.endYw.week > this.initialRelTimeRange.range ? {
                        year: this.endYw.year,
                        week: this.endYw.week - this.initialRelTimeRange.range
                    } : { 
                        year: this.endYw.year - 1, 
                        week: 52 - (this.initialRelTimeRange.range - this.endYw.week) 
                    };
                }
            },
            deep: true
        },
        currentYw: {
            handler: function() {
                this.ywRangeAcceptableYear = [ ...Array(5) ].map((_, i) => this.currentYw.year - i).sort();
            },
            deep: true
        },
        watchersForSettingChart() { this.setChart(); },
        isChecked() { this.updateChartAxes(this.isChecked); },
        selectedDataTypes() { this.isChecked = false; },
    },
    methods: {
        async setChart() {
            const isValidDate = this.validateDate();
            const isDataSelected = this.selectedDataTypes.some(el => el != null) ? true : false;
            if(isValidDate && isDataSelected) {
                this.switchisLoadingState();
                await this.renderPlotly();
                this.switchisLoadingState();
            }
        },
        async renderPlotly() {
            if(!this.classifTypes.map(el => el.value).includes(this.selectedClassif)) this.selectedClassif = 'no_classif'

            const isBarSelected = this.selectedDataTypes[0] != null;
            const isLineSelected = this.selectedDataTypes[1] != null;
            let data = [];
            let layout = { 
                barmode: this.selectedDataTypes[0] === 'stockout' ? 'group' : 'stack', 
                height: this.isDashboard ? 250 : 400, 
                legend: { 
                    orientation: 'h',
                    y: this.isDashboard ? -0.2 : -0.15
                },
                xaxis: this.isDashboard ? { 
                    type: 'category' ,
                    tickfont: { size: 10 }
                } : { type: 'category' },
                annotations: [{
                        showarrow: false,
                        xref: 'paper',
                        yref: 'paper',
                        x: 0,
                        y: 1,
                        opacity: 0.9,
                        text: 'データのない週は前週（最後にデータのある週）の値をオレンジ色で描画します。'
                }]
            };
            const config = {
                modeBarButtonsToRemove: ['resetScale2d', 'zoom', 'zoomIn2d', 'zoomOut2d', 'select2d', 'pan2d', 'lasso2d']
            };
            if(isBarSelected) {
                const _ductRetArray = await this.callDuct(this.selectedDataTypes[0]);
                data = [ ...data, ...this.composePlotlyData(_ductRetArray, this.selectedDataTypes[0], 'bar') ];
                [ layout.yaxis, layout.margin ] = this.setAxisLayoutAndMargin(true, this.selectedDataTypes[0]);
            }
            if(isLineSelected) {
                const _ductRetArray = await this.callDuct(this.selectedDataTypes[1], false);
                data = [ ...data, ...this.composePlotlyData(_ductRetArray, this.selectedDataTypes[1], 'line') ];
                [ layout.yaxis2, layout.margin ] = this.setAxisLayoutAndMargin(false, this.selectedDataTypes[1]);
            }
            this.isNoData = data.length == 0 || data[0].y.every(el => el === null); 
            if(!this.isNoData) {
                this.setAxesRange(data);
                Plotly.newPlot(this.$refs.chart, data, layout, config);
                this.$refs.chart.on('plotly_click', (data) => {
                    if(
                        data.points[0].data.type !== 'line' && 
                        this.selectedDataTypes[0] !== 'customers' && 
                        !data.points[0].data.isNull[data.points[0].pointIndex]
                    ) {
                        this.$emit('click:bar', {
                            initialYw: { year: Number(data.points[0].x.split('-')[0]), week: Number(data.points[0].x.split('-')[1]) },
                            initialDataType: data.points[0].data.dataType
                        });
                    }
                });
            }
        },
        composePlotlyData(ductRet, dataType, chartType) {
            let data = [];
            if(chartType == 'bar') {
                if(this.selectedClassif == 'no_classif') {
                    data.push({
                        x: ductRet.map(el => el.week),
                        y: ductRet.map(el => el.content.length != 0 ? el.content[0].value : null),
                        isNull: ductRet.map(el => el.content.length != 0 ? false : true),
                        name: `合計${this.dataTypes.find(el => el.value == this.selectedDataTypes[0]).text}`,
                        marker: {
                            color: ductRet.map(el => el.content.length != 0 ? '#1F77B4' : '#FF7043')
                        },
                        dataType: this.selectedDataTypes[0],
                        type: 'bar'
                    })
                    if(data[0].y.some(el => el !== null)){
                        for(const idx in data[0].y) {
                            if(idx !== 0) {
                                data[0].y[idx] = data[0].y[idx] === null ? data[0].y[idx-1] : data[0].y[idx]
                            }
                        }
                    }
                }else {
                    let classifList = [];
                    for(let wklyRet of ductRet) {
                        const wklyClassifList = wklyRet.content.length != 0 ? wklyRet.content.map(el => el.classifier) : [];
                        classifList = [ ...new Set([ ...classifList, ...wklyClassifList]) ];
                    } 
                    for(let classifier of classifList){
                        data.push({
                            x: ductRet.map(el => el.week),
                            y: ductRet.map(el => el.content.length != 0 && el.content.map(el => el.classifier).includes(classifier) ? el.content[el.content.map(el => el.classifier).indexOf(classifier)].value : (dataType === 'stockout' ? 0 : null)),
                            name: classifier,
                            dataType: this.selectedDataTypes[0],
                            classifier: classifier,
                            type: 'bar'
                        });
                    }
                }
            }else if(chartType == 'line') {
                data.push({
                    x: ductRet.map(el => el.week),
                    y: ductRet.map(el => el.content.length != 0 ? el.content[0].value : (dataType === 'stockout' ? 0 : null)),
                    name: this.dataTypes.find(el => el.value == this.selectedDataTypes[1]).text,
                    line: { width: 3 },
                    marker: { size: 10 },
                    type: 'line',
                    yaxis: 'y2'
                });
            }
            return data
        },
        setAxisLayoutAndMargin(isBar=true, dataType) {
            const margin = { r: 0, t: 0, b: 40 };
            const axisLayout = isBar ? { 
                exponentformat: 'none',
                title: { 
                    font: this.isDashboard ? { size: 12 } : {},
                    text: this.dataTypes.find(el => el.value == dataType).text, 
                    standoff: 20 
                },
                tickformat: this.selectedDataTypes[0] === 'stockout' ? ',.1%': '',
                tickfont: this.isDashboard ? { size: 10 } : {},
                automargin: 'left', 
            } : { 
                exponentformat: 'none',
                title: { 
                    font: this.isDashboard ? { size: 12 } : {},
                    text: this.dataTypes.find(el => el.value == dataType).text, 
                    standoff: 20 
                }, 
                tickformat: this.selectedDataTypes[1] === 'stockout' ? ',.1%': '',
                tickfont: this.isDashboard ? { size: 10 } : {},
                automargin: 'width' , 
                overlaying: 'y',
                side: 'right',
                rangemode: 'tozero'
            };
            return [ axisLayout, margin ]
        },
        async callDuct(dataType, isBar=true) {
            let _ductRetArray = [];
            const _deviation = (this.endYw.year - this.startYw.year) * 52 + this.endYw.week - this.startYw.week;
            for(let idx = 0; idx < _deviation + 1; idx++) {
                let _week = this.startYw.week + idx;
                let _year = this.startYw.year;
                while(_week > 52) {
                    _week = _week - 52;
                    _year++;
                }
                let _ductRet = await this.duct.call(this.duct.EVENT.CHART_TIME_SERIES_GET, { 
                    data_type: dataType, 
                    classif_type: isBar ? this.selectedClassif : 'no_classif', 
                    year: Number(_year), 
                    week: Number(_week) 
                });
                _ductRetArray.push({ 
                    week: `${_year}-${_week}`,
                    content: _ductRet.content === 'no_data' ? [] : _ductRet.content
                });
            }
            console.log(_ductRetArray)
            return _ductRetArray
        },
        setAxesRange(data) {
            const largestNumber = data.length <= 2 ? ( 
                Math.max(...data.map(el => Math.max(...el.y))) ) : ( 
                Math.max(...data.reduce(function(acc, cur) {
                    return acc.map(function(el, idx) { return el + cur.y[idx] });
                }, [...Array(data[0].y.length)].fill(0)))
            );
            this.axesRange = [0, largestNumber];
        },
        updateChartAxes(isChecked) {
            Plotly.relayout(this.$refs.chart, isChecked ? {
                'yaxis.range': this.axesRange,
                'yaxis2.range': this.axesRange
            } : {
                'yaxis.autorange': true,
                'yaxis2.autorange': true
            });
        },
        onResize() {
            const chartRef = this.$refs.chart;
            if(![this.cardWidth, 0].includes(chartRef.clientWidth)) {
                this.cardWidth = chartRef.clientWidth;
                if(chartRef.classList.contains('js-plotly-plot')) {
                    Plotly.relayout(this.$refs.chart, { width: this.cardWidth });
                }
            }
        },
        onClickClose() { this.$emit('click:close'); },
        getYwRange(arg) { [this.startYw, this.endYw] = arg; },
        validateDate() { return (this.startYw.year == this.endYw.year && this.startYw.week > this.endYw.week) ? false : true },
        switchisLoadingState() { this.isLoading = !this.isLoading; },
        changeEditModeState(bool) { this.isEditMode = bool; },
        purgeChart() { Plotly.purge(this.$refs.chart); }
    },
    mounted() {
        this.selectedClassif = this.initialClassif;
        if(this.initialDataType != 'not_selected') {
            if(typeof this.initialDataType == 'string') this.selectedDataTypes[0] = this.initialDataType;
            else this.selectedDataTypes = this.initialDataType;
        }
        this.duct.invokeOnOpen(async () => {
            console.log(this.initialRelTimeRange);
            [this.startYw, this.endYw] = this.initialAbsTimeRange.length != 0 ? this.initialAbsTimeRange : [{ year: null, week: null }, { year: null, week: null}];
            if(Object.keys(this.initialRelTimeRange.yw).length != 0) {
                this.endYw = this.initialRelTimeRange.yw;
                this.startYw = this.endYw.week > this.initialRelTimeRange.range ? {
                    year: this.endYw.year,
                    week: this.endYw.week - this.initialRelTimeRange.range
                } : { 
                    year: this.endYw.year - 1, 
                    week: 52 - (this.initialRelTimeRange.range - this.endYw.week) 
                };
            }
        });
    }
}
</script>
