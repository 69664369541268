<template>
    <v-row :class="rowClass">
        <v-col cols="12" class="pt-0">
            <chip-menu
                :list-items="acceptableYear"
                :chip-text="`${String(startYw.year)}年`"
                :chip-props="chipProps"
                chip-class="mr-1" 
                @click:list="emitYw(true, true, $event)"
            ></chip-menu>
            <chip-menu
                :list-items="weeks"
                :chip-text="`第${String(startYw.week)}週`"
                :chip-props="chipProps"
                chip-class="mr-1" 
                @click:list="emitYw(true, false, $event)"
            ></chip-menu>
            から
            <chip-menu
                :list-items="acceptableYear"
                :chip-text="`${String(endYw.year)}年`"
                :chip-props="chipProps"
                chip-class="mr-1" 
                @click:list="emitYw(false, true, $event)"
            ></chip-menu>
            <chip-menu
                :list-items="weeks"
                :chip-text="`第${String(endYw.week)}週`"
                :chip-props="chipProps"
                chip-class="mr-1" 
                @click:list="emitYw(false, false, $event)"
            ></chip-menu>
            まで
        </v-col>
    </v-row>
</template>
<script>
import ChipMenu from './ChipMenu'
export default {
    components: { ChipMenu },
    data() {
        return {
            startYw: {
                year: null,
                week: null
            },
            endYw: {
                year: null,
                week: null
            },
            weeks: [ ...Array(52) ].map((_, idx) => Number(idx) + 1) 
        }
    },
    props: {
        acceptableYear: {
            type: Array,
            default: () => ([2019, 2020, 2021, 2022])
        },
        initialYwRange: {
            type: Array,
            default: () => ([])
        },
        rowClass: {
            type: [Object, String],
            default: () => ({})
        },
        chipProps: {
            type: Object,
            default: () => ({
                outlined: true,
                label: true,
            })
        },
    },
    watch: {
        initialYwRange() {
            [this.startYw, this.endYw] = this.initialYwRange;
        },
    },
    methods: {
        emitYw(isStart=true, isYear=true, val) {
            if(isStart) {
                this.startYw = { 
                    year: isYear ? val : this.startYw.year, 
                    week: isYear ? this.startYw.week : val 
                };
            }else {
                this.endYw = { 
                    year: isYear ? val : this.endYw.year, 
                    week: isYear ? this.endYw.week : val 
                };
            }
            this.$emit('click', [ this.startYw, this.endYw ]);
        }
    },
    created() {
        if(this.initialYwRange.length !== 0) [ this.startYw, this.endYw ] = this.initialYwRange;
    } 
}
</script>
